import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <>
      <div className="header-dark">
        <nav className="navbar navbar-dark navbar-expand-md navigation-clean-search">
          <div className="container">
            <Link className="navbar-brand" to="/">
             
              <img
                src="https://webdroy.com/brick/logo.png"
                alt="Logo"
                className="img-fluid"
                style={{ height: 50 }}
              />
            </Link>
            <button
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#navcol-1"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navcol-1">
              <ul className="nav navbar-nav">
                <li className="nav-item" role="presentation">
                  <Link className="nav-link" to="/">
                    HOME
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link className="nav-link" to="/upload">
                    CREATE
                  </Link>
                </li>
                {/* <li className="nav-item" role="presentation">
                  <Link className="nav-link" to="/pricing">
                    PRICING
                  </Link>
                </li> */}
                 <li className="nav-item" role="presentation">
                  <Link className="nav-link" to="/gallery">
                    GALLERY
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link className="nav-link" to="/about">
                    ABOUT
                  </Link>
                </li>
               
                <li className="nav-item" role="presentation">
                  <Link className="nav-link" to="/contact">
                    CONTACT
                  </Link>
                </li>
              </ul>
              <div
                className="icons d-none d-md-flex align-items-center"
                style={{ paddingLeft: 120 }}
              >
                <a
                  href="/account"
                  className="text-white mx-2 rounded-circle d-flex justify-content-center align-items-center icon-container"
                  style={{ width: 40, height: 40 }}
                >
                  <i className="fas fa-user" />
                </a>
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  className="text-white mx-2 rounded-circle d-flex justify-content-center align-items-center icon-container"
                  style={{ width: 40, height: 40 }}
                >
                  <i className="fa fa-shopping-cart" aria-hidden="true" />
                </a>
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  className="text-white mx-2 rounded-circle d-flex justify-content-center align-items-center icon-container"
                  style={{ width: 40, height: 40 }}
                >
                  <i className="fab fa-facebook-f" />
                </a>
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  className="text-white mx-2 rounded-circle d-flex justify-content-center align-items-center icon-container"
                  style={{ width: 40, height: 40 }}
                >
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>

    </>
  )
}

export default Header