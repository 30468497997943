import React from 'react';

const Preloader = () => {
  return (
    <center style={{margin:'200px'}}>
    <div className="preloader">
      <span><img src="assets/img/Rhombus.gif" alt="" /></span>
    </div>
    </center>
  );
  };

export default Preloader;
