import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { GETAPI } from '../Service/Serivce';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import Preloader from '../Preloader';

const Frame = () => {
  const [loading, setLoading] = useState(true);
  const [Data, setData] = useState([]);
  const getView = async () => {
    try {
      const result = await GETAPI("Category");
      if (result) {
        setData(result);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getView();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
       {loading ? (
          <Preloader />
        ) : (
          <>
      <Header />
      <center>
        <div className='container'>
          <h1 style={{ padding: "30px" }} >Add Frame</h1>
          <h6>Select from one of the options below.</h6>
          <center>

            <Grid container spacing={5}>
              {Data.map((item) => (
                <Grid item key={item.categoryId} xs={12} sm={6} md={4}>
                  <Card
                    component={Link}

                    to={`/detail/${item.categoryId}`}
                    sx={{
                      maxWidth: 345,
                      height: '100%',
                      display: 'flex',
                      textDecoration: 'none',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      transition: 'transform 0.3s, box-shadow 0.3s',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: 6, // Adjust the shadow as needed
                      },
                    }}
                  >

                    <CardMedia
                      component="img"
                      alt={item.categoryName}
                      height="140"
                      
                      sx={{
                        height: 140,
                        
                        backgroundSize: 'cover', // Ensure the image covers the whole area
                        backgroundPosition: 'center' // Center the image
                      }}
                      image={item.imageUrl}
                      title={item.categoryName}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {item.categoryName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {item.description}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'center', paddingBottom: '16px' }}>
                      <Button sx={{
                        '&:hover': {
                          backgroundColor: 'primary.main',
                          color: 'white',
                          transform: 'scale(1.05)',
                          transition: 'transform 0.3s ease-in-out',
                        },
                      }} size="small">From $ {item.price}</Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </center>
        </div>
      </center>
      <br /> <br />
      <Footer />
      </>
          )}
    </>
  )
}

export default Frame