import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { toast } from "react-toastify";
import { baseUrl } from '../Service/Register';


const Upload = () => {
  const [uploadImage, setUploadImage] = useState("");

  const [OrderNo, setOrderNo] = useState("");
  const fileInput = document.getElementById("file-upload");
  const handleImage = async (e, id) => {
    localStorage.clear();
    try {
      const response = await fetch(`${baseUrl}/api/Order/GenerateOrderNo`, {
        method: 'POST',

      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setOrderNo(result.data);
      localStorage.setItem('OrderNo', result.data)
      if (result.message === "Order number generated successfully") {
        const file = e.target.files[0];
        if (file) {
          if (!file.type.startsWith('image/')) {
            toast.error('Please select an image file.');
            return;
          }

          const reader = new FileReader();

          // Read the file as a Data URL (Base64)
          reader.readAsDataURL(file);

          // On load success
          reader.onload = async () => {
            const imageBase64 = reader.result;
            setUploadImage(imageBase64);
            const imageData = imageBase64.split(',')[1];
            // localStorage.setItem('PrimaryImage', imageData);

            const ImgformData = new FormData();
            ImgformData.append("formFile", imageData); // Directly append the file
            ImgformData.append("OrderNo", result.data);

            try {
              const response = await fetch(`${baseUrl}/api/Order/SavePrimaryImage`, {
                method: 'POST',
                body: ImgformData,
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem("token")}`,
                }
              });

              if (!response.ok) {
                throw new Error('Network response was not ok');
              }

              const result = await response.json();
              toast.success(result.message);
            } catch (error) {
              console.error('Error saving primary image:', error);
              toast.error('Failed to save primary image');
            }
          };

          // On load error
          reader.onerror = (error) => {
            console.error('Error reading file:', error);
            alert('Error reading file.');
          };
        }
      }
      else {
        toast.error('Something went wrong . Please try again ')
      }

    } catch (error) {
      console.error('Error saving primary image:', error);
      toast.error('Failed to save primary image');
    }

  };


  // const handleImage = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     if (!file.type.startsWith('image/')) {
  //       toast.error('Please select an image file.');
  //       return;
  //     }
  //     const reader = new FileReader();

  //     // Read the file as a Data URL (Base64)
  //     reader.readAsDataURL(file);

  //     // On load success
  //     reader.onload = () => {
  //       setUploadImage(reader.result);
  //       localStorage.setItem('Image', reader.result);
  //       const data = reader.result.split(',')[1];
  //       localStorage.setItem('PrimaryImage', data);
  //     };

  //     // On load error
  //     reader.onerror = (error) => {
  //       console.error('Error reading file:', error);
  //       alert('Error reading file.');
  //     };
  //   }
  // };
  const imageChange = () => {
    fileInput.click();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const base64Image = localStorage.getItem('Image');
    if (base64Image) {
      setUploadImage(base64Image);
    }
  }, []);

  return (
    <>
      <Header />
      <center>
        <div className="main-container">
          <div className="upload-container">
            <h2>Upload Your "Main Picture"</h2>
            <p style={{ color: "blue" }}>
              Press the add button below to select your primary photo and then
              press the upload button at the bottom.
            </p>
            <p style={{ color: "red", marginTop: "-25px" }}>
              Select ONE photo only
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={40}
              height={40}
              fill="currentColor"
              className="bi bi-file-arrow-up"
              viewBox="0 0 16 16"
            >
              <path d="M8 11a.5.5 0 0 0 .5-.5V6.707l1.146 1.147a.5.5 0 0 0 .708-.708l-2-2a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L7.5 6.707V10.5a.5.5 0 0 0 .5.5" />
              <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
            </svg>{" "}
            <br />
            <h3>Upload Image</h3>
            <input
              type="file"
              onChange={handleImage}
              id="file-upload"
              accept="image/*"
            />
            <label htmlFor="file-upload">Choose File</label>
          </div>
          <div className="library-container" id="library-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={40}
              height={40}
              fill="currentColor"
              className="bi bi-collection"
              viewBox="0 0 16 16"
            >
              <path d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6zm1.5.5A.5.5 0 0 1 1 13V6a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5z" />
            </svg>
            <h2>Library</h2>
            <p>Click to view your image library</p>
          </div>
        </div>
        <br />
        {uploadImage && (
          <div>
            <img
              src={uploadImage}
              style={{ borderRadius: "20px" }}
              height="600px"
              width="600px"
            />
          </div>
        )}

        {uploadImage && (
          <div
            style={{ display: "flex", justifyContent: "center", gap: "40px" }}
          >
            <button onClick={imageChange}>Change</button>
            <a href="/image-upload">
              <button>Continue</button>
            </a>
          </div>
        )}

        <div className="spacer" />
        <div className="info-text">
          Don’t want to design it yourself? For a small fee, we can create a
          design for you based on your image and instructions.{" "}
          <a href="#">Click here to learn more.</a>
        </div>
        <div className="spacer" />
        <div className="info-text1">
          What pics are best to Mosaic Photos? <a href="#">Read our tips</a>
        </div>
        <div className="overlay" id="overlay" />
        <div className="popup" id="popup">
          <h2>Image Library</h2>
          <p>Your uploaded images will appear here.</p>
          <button className="popup-close" id="popup-close">
            Close
          </button>
        </div>
      </center>
      <Footer />
    </>
  );
};

export default Upload;
