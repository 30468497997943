import React, { useEffect } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'

const Contact = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
      },[])
    return (
        <>
            <Header />
            {/* <!-- Contact --> */}

            <div className="concontainer">
                <div className="row">
                    <center>
                        <h2 className="conh2">Contact Us </h2>
                        <p className="conp"> At Mosaic Photos Australia, we value your satisfaction above all else. Your feedback and comments are essential to our success. Here’s how you can reach us: <br />

                            If you have any questions, feel free to explore our <a href="#">Frequently Asked Questions</a> section or reach out to us via email or phone.

                        </p>
                    </center>
                    <div className="col-md-6 contact-form">
                        <h2>Send us a message
                        </h2>
                        <form>
                            <input type="text" name="name" placeholder="Name" required />
                            <input type="email" name="email" placeholder="Email" required />
                            <input type="tel" name="phone" placeholder="Phone Number" required />
                            <textarea name="message" rows="4" placeholder="Message" required></textarea>
                            <select name="reason" required>
                                <option value="" disabled selected hidden>How did you hear about us?</option>
                                <option value="google">Google</option>
                                <option value="facebook">Facebook</option>
                                <option value="instagram">Instagram</option>
                                <option value="twitter">Twitter</option>
                            </select>
                            <button type="submit">Send Message</button>
                        </form>
                    </div>
                    <div className="col-md-6 social-section">
                        <ul className="csocial-icons">
                            <h2>Contact Informations
                            </h2>

                            <li className="cmail">
                                <div>
                                    <span></span>
                                    <cmails className="cmailsinfo">
                                        <strong>General enquiries:</strong> info@mosaicphotos.com.au
                                        <br />
                                        <strong>Order tracking:</strong> sales@mosaicphotos.com.au <br />
                                        <strong>Phone:</strong> 0416 273 308 <br />
                                        <strong> International:</strong> +61 416 273 308 <br />
                                        <strong>  Postal Address:</strong> 6 Bobbie Court, Dayboro Q 4521 <br />
                                        <strong>Business Hours:</strong> 8am to 6pm (AEST)
                                        7 days a week, 365 days a year.
                                    </cmails>


                                </div>
                            </li>


                            <li className="cfacebook">
                                <i className="fab fa-facebook-f"></i>
                                <div>
                                    <span>Follow us on Facebook</span>
                                    <span className="username">@YourFacebookPage</span>
                                </div>
                            </li>
                            <li className="ctwitter">
                                <i className="fab fa-twitter"></i>
                                <div>
                                    <span>Follow us on Twitter</span>
                                    <span className="username">@YourTwitterHandle</span>
                                </div>
                            </li>
                            <li className="cinstagram">
                                <i className="fab fa-instagram"></i>
                                <div>
                                    <span>Follow us on Instagram</span>
                                    <span className="username">@YourInstagramHandle</span>
                                </div>
                            </li>
                            <li className="cyoutube">
                                <i className="fab fa-youtube"></i>
                                <div>
                                    <span>Subscribe to our YouTube channel</span>
                                    <span className="username">@YourYouTubeChannel</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Contact
