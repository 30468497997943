import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "./Register";

const initialState = {
  posts: [],
  status: "idle",
  error: null,
};
const apiUrl =  `${baseUrl}/Authenticate`; // Replace with your API endpoint URL

export const LoginPosts = createAsyncThunk("posts/fetchPosts", async (body) => {
  try {
    const response = await axios.post(apiUrl, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    // Handle errors
    console.error("Error fetching posts:", error);
    throw error;
  }
});

const loginSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    // You can add additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginPosts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(LoginPosts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.posts = action.payload;
      })
      .addCase(LoginPosts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default loginSlice.reducer;
