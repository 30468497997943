import React from 'react'
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'

const PhotoGallery = () => {
    return (
        <>
            <Header />
            <center>
                <section class="aboutBanner">

                    <img src="assets/img/Mosaico-Foto.webp" class="img-fluid" />

                </section>

                <section class="galleryBox">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-10 mx-auto">
                                <div class="titleBox">
                                    <h2>Photo Gallery</h2>
                                </div>
                                <div class="galleryContent">
                                    <div class="galleryItem">
                                        <a href="assets/img/Gallery-5.png" data-fancybox="gallery">
                                            <img src="assets/img/Gallery-5.png" />
                                        </a>
                                    </div>
                                    <div class="galleryItem">
                                        <a href="assets/img/Gallery-2.png" data-fancybox="gallery">
                                            <img src="assets/img/Gallery-2.png" />
                                        </a>
                                    </div>
                                    <div class="galleryItem">
                                        <a href="assets/img/Gallery-3.png" data-fancybox="gallery">
                                            <img src="assets/img/Gallery-3.png" />
                                        </a>
                                    </div>
                                    <div class="galleryItem">
                                        <a href="assets/img/Gallery-3.png" data-fancybox="gallery">
                                            <img src="assets/img/Gallery-4.png" />
                                        </a>
                                    </div>
                                    <div class="galleryItem">
                                        <a href="assets/img/Gallery-4.png" data-fancybox="gallery">
                                            <img src="assets/img/Gallery-1.png" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </center>
            <Footer />
        </>
    )
}

export default PhotoGallery