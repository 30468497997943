import React from 'react'

const Error = () => {
    return (
        <>
            <div>
                <h3>404 page not found</h3>
                <p>The page you are looking for might be removed or is temporarily unavailable</p>
            </div>
        </>
    )
}

export default Error
