import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "./Register";

const initialState = {
  posts: [],
  status: "idle",
  error: null,
};

const apiUrl = `${baseUrl}/ResetPassword`; // Replace with your API endpoint URL

export const ChangePosts = createAsyncThunk(
  "posts/fetchPosts",
  async (body) => {
    try {
      const response = await axios.post(apiUrl, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      // Handle errors
      console.error("Error fetching posts:", error);
      throw error;
    }
  }
);

const ChangeSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    // You can add additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(ChangePosts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(ChangePosts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.posts = action.payload;
      })
      .addCase(ChangePosts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default ChangeSlice.reducer;
