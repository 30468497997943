import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChangePosts } from '../Service/Forgot';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';


const Forgot = () => {
    const [Password, setPassword] = useState();
    const [CPassword, setCPassword] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const validatePasswords = (pass, confirmPass) => {
        if (pass !== confirmPass) {
            setError('Passwords do not match');
        } else {
            setError('');
        }
    };
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        validatePasswords(e.target.value, CPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        setCPassword(e.target.value);
        validatePasswords(Password, e.target.value);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('EmailAddress', id);
        formData.append('NewPassword', Password);
        const response = await dispatch(ChangePosts(formData));
        console.log(response.payload)
        if (response.payload && response.payload.message === "User login successfully") {
            toast.success("Logged In successfully");
            localStorage.setItem('token', response.payload.data[0].Token)
            navigate("/login");
        }
    };

    return (
        <center>
            <div className="login-container">
                <div className="login-header">
                    <h2 className="forgot-password-heading">Forgot Your Password?</h2>
                    <p className="forgot-password-text">Enter your email to reset password</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input type="email" id="email" value={id} name="email" placeholder="Email" required readOnly />
                    </div>
                    <div className="loginform-groupp">
                        <input type="password" id="password" name="password"
                            placeholder="Password" required onChange={handlePasswordChange} />
                        <input type="password"
                            id="confirm_password"
                            name="confirm_password"
                            placeholder="Confirm Password"
                            required
                            onChange={handleConfirmPasswordChange} />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                    </div>
                    <div className="form-group">
                        <button type="submit">Change Password</button>
                    </div>
                
                </form>
            </div>
        </center>
    
    );
};

export default Forgot;
