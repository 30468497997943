import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { GETAPI } from '../Service/Serivce';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Preloader from '../Preloader';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        height: 400,
    },
    tab: {
        color: 'white',
        width: 'maxContent',
    },
    tabPanel: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
}));

const Details = () => {
    const classes = useStyles();
    const theme = useTheme();
    // -------------------------------TAB BOX START-------------------------------------
    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    };

    const a11yProps = (index) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    };

    const [value, setValue] = useState(0);

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    // ---------------------------TAB BOX END---------------------------


    // ---------------------------TABLE START---------------------------


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    // ---------------------------TABLE END-----------------------------


    // --------------------------API CALL START-------------------------------

    const [Data, setData] = useState([]);
    const [Product, setProduct] = useState([]);
    const [ProductID, setProductID] = useState([]);
    const [loading, setLoading] = useState(true);
    const [Size, setSize] = useState([]);
    const { id } = useParams();
    localStorage.setItem('Frame', id)
    localStorage.getItem("Frame")
    const getView = async () => {
        try {
            const result = await GETAPI(`Category/${localStorage.getItem("Frame")}`);
            if (result) {
                setData(result);
            }
            const result1 = await GETAPI(`Product/GetProductByCategoryId/${localStorage.getItem("Frame")}`);
            if (result1) {
                setProduct(result1);
                setLoading(false);
                setProductID(result1[0].productId);
                getView1(result1[0].productId)
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const getView1 = async (id) => {
        try {
            const result = await GETAPI(`Size/GetSizeByProduct/${id}`);
            if (result) {
                setSize(result);
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
  
    useEffect(() => {
        window.scrollTo(0, 0);
        getView();
    }, []);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        const selectedProduct = Product[newValue];
        if (selectedProduct) {
            getView1(selectedProduct.productId);
        }
    };
    // --------------------------API CALL END---------------------------------
    return (
        <>
           {loading ? (
          <Preloader />
        ) : (
          <>
            <Header />
            <center>

                <div className='container'>
                    <div className='detailOrder'>
                        <a> ORDER </a>
                        <a> PRIMARY </a>
                        <a> CELLS </a>
                        <a> PRINT </a>
                        <a> OPTIONS </a>
                        <a> CONFIRMATION </a>
                    </div>
                    <div className='detailContainer'>
                        <h1 style={{ padding: "30px" }} >{Data.categoryName}</h1>
                        <p className='detailP'>{Data.description}</p>
                        <h6>Select what size you would like from the list below</h6>



                        <Box sx={{ bgcolor: 'background.paper', width: 500 }}>

                            <>
                                <AppBar position="static">
                                    <div>
                                        <Tabs
                                            sx={{ maxWidth: 'max-content' }}
                                            value={value}
                                            onChange={handleChange}
                                            aria-label="FRAME SIZE"

                                        >
                                            {Product.map((item, index) => (
                                                <Tab sx={{

                                                    color: 'white',
                                                    '&.Mui-selected': {
                                                        color: 'white',
                                                        backgroundColor: 'rgb(17, 86, 159)', // Darker shade of #1976d2
                                                    },
                                                }}
                                                    label={item.productName} {...a11yProps(index)}
                                                    key={item.categoryId} className={classes.tab}

                                                />
                                            ))}

                                        </Tabs>
                                    </div>
                                </AppBar>

                                <SwipeableViews
                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                >
                                    {Product.map((item, index) => (
                                        <TabPanel value={value} index={index} dir={theme.direction} key={item.categoryId} >
                                            <Typography>{item.description}</Typography>
                                            <img src={item.imageUrl} alt={item.productName} className={classes.image} />
                                        </TabPanel>
                                    ))}

                                </SwipeableViews>
                            </>

                        </Box>


                        <Table aria-label="customized table">
                            <TableBody>
                                {Size.map((row) => (
                                    <StyledTableRow key={row.name} component={Link} to={row.height === 0 && row.width === 0 ? `/custom/${row.sizeId}` : `/option/${row.sizeId}`}>

                                        <StyledTableCell align="center" >{row.description} </StyledTableCell >
                                        <StyledTableCell align="center"> {row.height === 0 && row.width === 0
                                            ? 'You Choose'
                                            : `${row.height} cm × ${row.width} cm`}</StyledTableCell>
                                        <StyledTableCell sx={{ color: 'maroon', fontWeight: '800' }} align="center">  {row.price === 0 ? 'tbc*' : `$ ${row.price}`}</StyledTableCell>

                                    </StyledTableRow>
                                ))}


                            </TableBody>
                        </Table>

                    </div>
                </div>
                <p style={{ fontSize: '12px', color: 'blue' }}>* On the next page you can choose your own size.
                    We will find a print size to closely match your chose size.
                    We will email you with the calculated price.</p>
            </center>
            <Footer />
            </>
        )}
        </>
    )
}

export default Details
