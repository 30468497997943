import React, { useState } from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

const Test = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const handleImageChange = (event) => {
    const files = event.target.files;
    if (files) {
      const selected = Array.from(files);
      setSelectedImages((prevImages) => [...prevImages, ...selected]);

      const previews = [];
      selected.forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          previews.push(reader.result);
          if (previews.length === selected.length) {
            setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedImages.length > 0) {
      // Perform upload or other logic with selected images
      console.log('Selected images:', selectedImages);
    }
  };

  return (
    <>
      <Header />
      <div>
        <form onSubmit={handleSubmit}>
          <input type="file" accept="image/*" multiple onChange={handleImageChange} />
          {imagePreviews.map((preview, index) => (
            <img key={index} src={preview} alt={`Image Preview ${index}`} width="100" />
          ))}
          <button type="submit">Upload Images</button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Test;
