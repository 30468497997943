import { configureStore } from "@reduxjs/toolkit";
import postsSlice from "./Register";
import loginSlice from "./Login";
import ChangeSlice from './Forgot'

const store = configureStore({
  reducer: {
    api: postsSlice,
    api: loginSlice,
    api :ChangeSlice,
   
  },
});

export default store;
