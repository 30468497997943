import React from 'react'

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="widget1">
                <div className="logo">
                  <img
                    src="https://webdroy.com/brick/logo.png"
                    className="img-fluid"
                    alt="Logo"
                  />
                </div>
                <div className="socialLinks">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-google-plus-g" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-2">
              <div className="widget3">
                <ul>
                  <li>
                    <a href="/">HOME</a>
                  </li>
                  <li>
                    <a href="/upload">CREATE</a>
                  </li>
                  <li>
                    <a href="/gallery">GALLERY</a>
                  </li>
                  <li>
                    <a href="#">ABOUT</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-lg-2">
              <div className="widget3"></div>
            </div>
            <div className="col-sm-6 col-lg-2">
              <div className="widget4">
                <ul>
                  <li>
                    <a href="/privacy">PRIVACY</a>
                  </li>
                  <li>
                    <a href="/terms">TERMS</a>
                  </li>
                  <li>
                    <a href="/contact">CONTACT</a>
                  </li>
                  <li>
                    <a href="/Faq">FAQ</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyRightArea">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <p>
                  © 2020 Mosaic Photos Australia | <a href="webdroy.com"> Webdroy </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>

    </>
  )
}

export default Footer