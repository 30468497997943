import React, { useEffect, useState } from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { Button, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { baseUrl } from '../Service/Register';
import { GETAPI } from '../Service/Serivce';

const Confirmation = (props) => {
  const [Size, setSize] = useState([]);
  const sizeId = localStorage.getItem("Size");
  const getView1 = async () => {
    try {

      const result = await GETAPI(`Size/GetSizeByProduct/${localStorage.getItem("Frame")}`);
      if (result) {
        setSize(result);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [description, setDescription] = useState('');
  useEffect(() => {
    const sizeId = localStorage.getItem("Size");
    if (Size.length > 0 && sizeId) {
      const foundSize = Size.find(item => item.sizeId === Number(sizeId));
      if (foundSize) {
        setDescription(foundSize.description);
      } else {
        setDescription('No matching size found.');
      }
    }
  }, [Size]);

  useEffect(() => {
    // getView();
    getView1();
  }, [])
  const today = new Date();
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const formattedDate = today.toLocaleDateString('en-GB', options).split('/').reverse().join('-');


  const width = localStorage.getItem('width');
  const height = localStorage.getItem('height');
  const formData = new FormData();
  const formData1 = new FormData();
  const navigate = useNavigate(); // Hook for navigation
  const token = localStorage.getItem('token'); // Get token from localStorage
  
  const handleContinueClick = async () => {

    if (token) {

      formData.append("OrderNo", localStorage.getItem("OrderNo"));
      formData.append("CustomerId", localStorage.getItem("Id"));
      formData.append("OrderDate", formattedDate);
      formData.append("SizeId", localStorage.getItem("Id"));
      formData.append("CustomWidth", width ? width : '0');
      formData.append("CustomHeight", height ? height : '0');
      formData.append("CellSize", sessionStorage.getItem("Length"));
      formData.append("ItemPrice", '0');
      formData.append("ExtrasPrice", '0');
      formData.append("DeliveryType", '0');
      formData.append("DeliveryPrice", '0');
      formData.append("PayPal", '0');
      formData.append("TotalPrice", '0');
      formData.append("SampleNo", '0');
      formData.append("PurchaseDate", formattedDate);
      // formData.append("PurchaseDate", "31-08-2024");
      formData.append("DeliveryAddress", '0');
      formData.append("TrackingUrl", '-');
      formData.append("Status", '1');
      formData.append("Discount", '0');
      formData.append("DiscountId", '0');
      try {
        const response = await fetch(`${baseUrl}/api/Order/CreateOrder`, {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            Accept: "*/*",
          },
        });
        const data = await response.json();
        if (data && data.message === "Order Created successfully") {
          toast.success("Submitted successfully");
          formData1.append("order", localStorage.getItem('OrderNo'));
          try {
            const response = await fetch(`${baseUrl}/api/Order/ChangeFolderStructure`, {
              method: "POST",
              body: formData1,
              headers: {
                Authorization: `${localStorage.getItem("token")}`,
                Accept: "*/*",
              },
            });
            const data = await response.json();
            if (data && data.success) {
              // localStorage.clear();
              navigate('/account');
              // sessionStorage.clear();
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          toast.error(data.result.message);
        }
      } catch (error) {
        console.error(error);
      }

    } else {
      localStorage.setItem('submittingOrder', 'Yes')
      navigate('/login');

    }
  };


  const [primaryImage, setPrimaryImage] = useState('');
  const [cellImage, setCellImage] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const Image = localStorage.getItem('Image');
    if (Image) {
      setPrimaryImage(Image);
    }
  }, []);

  const handleCancelOrder = () => {
    localStorage.clear();
    sessionStorage.clear();
    toast.success('Order Cancelled')
    navigate('/')
  }

  return (
    <>
      <Header />
      <center>
        <div className='mycontainer'>
          <h4>Order Confirmation</h4>
          <p>Press on the Submit Button below.
            <br />
            You can change or cancel your order at any time.</p>
          <p><b>This is a 100% obligation-free process.</b></p>
          <Button
            variant="contained"
            sx={{
              marginLeft: '10px',
              bgcolor: '#1565c0',
              '&:hover': {
                bgcolor: '#0d47a1',
                color: 'white',
              },
              boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
              color: 'white',
            }}
            onClick={handleContinueClick}
            style={{ marginLeft: '10px' }}


            size="large"
          >
            Submit
          </Button>
          <div className='optionDiv'>

            <div>
              {primaryImage ? (
                <img style={{ height: '500px' }} src={primaryImage} alt="Primary" />
              ) : (
                ''
                // <p>No image available</p>
              )}
            </div>
            <a href='/upload' style={{ color: 'blue', fontSize: '14px' }}>Change Primary Photo</a>
            <div>

              <p>{description}</p>
              <a href={`/detail/${localStorage.getItem('Frame')}`} style={{ color: 'blue', fontSize: '14px' }}>Change Print Type</a>
              <div>

                <Table aria-label="customized table" sx={{ width: '50%' }}>
                  <TableBody>
                    <TableRow sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' } }}>
                      <TableCell align="center">Cell Count:</TableCell>
                      <TableCell align="center">{sessionStorage.getItem("Length")}</TableCell>
                      <TableCell align="center">
                        <Link to='/image-upload' style={{ color: 'blue' }}>ADD</Link>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' } }}>
                      <TableCell align="center">Cell Size:</TableCell>
                      <TableCell align="center">{localStorage.getItem("Option")}</TableCell>
                      <TableCell align="center">
                        <Link to={`/option/${localStorage.getItem("Option")}`} style={{ color: 'blue' }}>CHANGE</Link>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <br />
                <Button variant="contained" onClick={handleCancelOrder}>Cancel Order</Button>
              </div>
            </div>
          </div>
        </div>
      </center>
      <Footer />
    </>
  );
};

export default Confirmation;
