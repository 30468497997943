import React from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'

const Preview = () => {
    return (
        <>

            <Header />
            <center>
                <section class="aboutBanner">

                    <img src="assets/img/Mosaico-Foto.webp" class="img-fluid" />

                </section>


                <section class="aboutContent">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-10 mx-auto">
                                <div class="titleBox">
                                    <h2>Preview</h2>
                                </div>
                                <div class="aboutContent-box">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non tempor nisi. Donec consequat arcu eget est accumsan, eu posuere lorem dignissim. Nullam pretium fringilla mi at dictum. Integer ut lacus dictum, vestibulum augue vitae, placerat urna. Phasellus at viverra orci, mollis lacinia enim. Fusce dui risus, commodo sit amet viverra at, aliquam eu urna. Integer ut massa commodo, consectetur nibh pellentesque, sodales urna.</p>

                                    <p>Phasellus eget massa justo. Integer vitae neque imperdiet, dapibus erat ut, interdum lectus. Etiam mattis pretium diam mollis laoreet. Integer ipsum eros, tristique sit amet tellus nec, interdum sodales sapien. Ut volutpat dapibus luctus. Pellentesque hendrerit quam quis metus pulvinar, vel ultricies quam convallis. In sed tempor sem. Donec feugiat feugiat cursus. Nam lobortis non arcu nec vulputate. Vestibulum fermentum malesuada libero, et tristique dui vestibulum eu. Curabitur leo libero, interdum vel ante id, eleifend faucibus nibh. Mauris sed felis et nibh luctus accumsan.</p>

                                    <p>Donec non urna nibh. Aliquam tempus auctor tortor et lacinia. Maecenas vitae libero a eros iaculis condimentum. Aliquam erat volutpat. Donec ut egestas libero. Vestibulum justo odio, tincidunt vitae mattis ac, condimentum vitae diam. Curabitur vitae dui auctor, molestie massa id, molestie nunc. Fusce a orci pharetra, pulvinar dui nec, tempor neque.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </center>
            <Footer />


        </>
    )
}

export default Preview