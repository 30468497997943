import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Button } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import Preloader from '../Preloader';
const Option = () => {
    const { id } = useParams();
    const [Option, setOption] = useState('');
    const [loading, setLoading] = useState(true);
    localStorage.setItem('Size', id)
    localStorage.setItem('Option', Option)

    useEffect(()=>{
        window.scrollTo(0, 0);
        setLoading(false)
      },[])
    return (
        <>
   {loading ? (
          <Preloader />
        ) : (
          <>
            <Header />
            <center>
                <div className='mycontainer'>
                    <h4>Choose Your Options</h4>
                    <h6>It looks like you have some options to think about.</h6>
                    <div className='optionDiv'>
                        <FormControl>
                            <FormLabel sx={{ fontSize: '30px', color: ' #0071bc' }} id="demo-radio-buttons-group-label">Cell Size</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                onChange={(e) => setOption(e.target.value)}
                            >
                                <FormControlLabel value="default" control={<Radio />} label="Default (Let us decide)" />
                                <FormControlLabel value="xs(9)" control={<Radio />} label="X-Small (9mm)" />
                                <FormControlLabel value="s(12)" control={<Radio />} label="Small (12mm)" />
                                <FormControlLabel value="m(15)" control={<Radio />} label="Medium (15mm)" />
                                <FormControlLabel value="l(18)" control={<Radio />} label="Large (18mm)" />
                                <FormControlLabel value="xl(21)" control={<Radio />} label="X-Large (21mm)" />
                                <FormControlLabel value="xxl(24)" control={<Radio />} label="XX-Large (24mm)" />

                            </RadioGroup>

                        </FormControl>
                        <p style={{ color: ' #0071bc' }}>Hint: The smaller the cell the better the mosaic effect. The larger the cell the clearer the cell photos, however the mosaic effect is more 'blocky'</p>
                    </div>

                    <div className='buttongrp'>
                        <Button variant="contained">Cancel</Button>
                        <Button variant="contained" component={Link}
                            to={`/confirmation`}>Continue</Button>
                    </div>

                </div>
            </center>
            <Footer />
           </> )}
        </>
    )
}

export default Option
