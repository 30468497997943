import React from 'react'
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'

const Create = () => {
    return (
        <>
            <Header />
            <center>
                <div className='container'>
                    <h4>Mosaic Photo Creator</h4>
                    <h5>Four Easy to Follow Steps</h5>
                    <div className='optionDiv'>
                        <div className='createDiv'>
                        <h6>1. Login or Create an Account</h6>
                        <h6>2. Upload your main photo</h6>
                        <h6>3. Upload your small cell photos</h6>
                        <h6>4. Choose a print option</h6>
                        </div>
                    </div>

                </div>
            </center>
            <Footer />
        </>
    )
}

export default Create
