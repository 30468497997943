import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import { Box, Button, FormControl, FormLabel, InputLabel, TextField } from '@mui/material'
import { Link } from 'react-router-dom'

const Custom = () => {
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();
    const handleWidthChange = (event) => {
        // Ensure only numbers are entered (optional)
        const value = event.target.value.replace(/\D/, '');
        setWidth(value);
        console.log("width", width)
    };
    const handleheightChange = (event) => {
        // Ensure only numbers are entered (optional)
        const value = event.target.value.replace(/\D/, '');
        setHeight(value);

    };
    const handleContinueClick = () => {
        localStorage.setItem('width',width);
        localStorage.setItem('height',height)
        console.log('Width:', width);
        console.log("height", height)

    };

    const handleCancelClick = () => {
        // Handle logic for 'Cancel' button click
        setWidth('');
        setHeight(''); // Reset width state or perform any other cleanup
    };
    useEffect(()=>{
        window.scrollTo(0, 0);
      },[])
    return (
        <>
            <Header />
            <center>
                <div className='mycontainer'>
                    <h4>Custom Size Mosaic</h4>
                    <p>Enter in your preferred width and height of your mosaic print.
                        We will calculate a price and get back to you shortly.</p>
                    <div className='optionDiv1'>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputLabel htmlFor="component-simple">Width (in cm)</InputLabel>
                            <TextField
                                id="outlined-basic"
                                label="Width (in cm)"
                                variant="outlined"
                                type="number"
                                value={width}
                                onChange={handleWidthChange}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                            <InputLabel htmlFor="component-simple">Height (in cm)</InputLabel>
                            <TextField
                                id="outlined-basic"
                                label="Height (in cm)"
                                variant="outlined"
                                type="number"
                                value={height}
                                onChange={handleheightChange}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            />
                        </Box>
                    </div>
                    <div className='buttongrp'>
                        <Button
                            sx={{
                                marginLeft: '10px',
                                bgcolor: '#1565c0',
                                '&:hover': {
                                    bgcolor: '#0d47a1',
                                    color: 'white',
                                },
                                boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
                                color: 'white',
                            }}
                            variant="contained"
                            onClick={handleCancelClick}
                            style={{ marginLeft: '10px' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                marginLeft: '10px',
                                bgcolor: '#1565c0',
                                '&:hover': {
                                    bgcolor: '#0d47a1',
                                    color: 'white',
                                },
                                boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
                                color: 'white',
                            }}
                            onClick={handleContinueClick}
                            style={{ marginLeft: '10px' }}
                            component={Link}
                            to={`/option/2`}
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </center>
            <Footer />
        </>
    )
}

export default Custom
